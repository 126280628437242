<template>
    <div class="slide" ref="slide" :class="slideType">
        <b-container class="h-100">
            <b-row class="h-100">
                <b-col class="px-sm-4">
                    <div class="top_container" ref="topTextContainer">
                        <div
                            class="text header-1 top_text"
                            ref="topText"
                            v-html="`${slide.title}`"
                            v-if="slideType == 'initial' || slideType == 'desc'"
                        ></div>
                        <div
                            class="text header-1 top_text"
                            ref="topText"
                            v-html="`${splitTitle[0]}`"
                            v-if="slideType == 'default'"
                        ></div>
                    </div>
                    <div class="bottom_container" ref="bottomTextContainer">
                        <div
                            class="text header-1 bottom_text"
                            ref="bottomText"
                            v-html="`${slide.title}`"
                            v-if="slideType == 'initial' || slideType == 'desc'"
                        ></div>
                        <div
                            class="text header-1 bottom_text"
                            ref="bottomText"
                            v-html="`${splitTitle[1]}`"
                            v-if="slideType == 'default'"
                        ></div>
                    </div>

                    <div
                        class="sub_title"
                        ref="subTitle"
                        v-html="slide.sub_text"
                    ></div>

                    <button
                        type="button"
                        class="read_more action-btn"
                        ref="readMoreBtn"
                        v-if="slideType == 'default'"
                        @click="showCase(slide.id)"
                    >
                        Подробнее
                    </button>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import { TimelineLite, gsap } from "gsap";

export default {
    name: "ContentSlide",
    props: {
        slide: { default: {} },
        slideIdx: { default: 0 },
        currentSlide: { default: 0 },
    },
    data: () => ({}),
    computed: {
        timeline: () => new TimelineLite({ paused: true }),
        topText() {
            return this.$refs.topText;
        },
        bottomText() {
            return this.$refs.bottomText;
        },
        topTextContainer() {
            return this.$refs.topTextContainer;
        },
        bottomTextContainer() {
            return this.$refs.bottomTextContainer;
        },
        subTitle() {
            return this.$refs.subTitle;
        },
        readMoreBtn() {
            return this.$refs.readMoreBtn;
        },
        slideType() {
            // eslint-disable-next-line no-nested-ternary
            return this.slideIdx === 0
                ? "initial"
                : (this.slideIdx === 1 ? "desc" : "default") // eslint-disable-line
        },
        splitTitle() {
            return this.slide.title.split("<br>");
        },
    },
    mounted() {
        gsap.set(this.$refs.slide, { x: `${this.slideIdx * -100}%` });

        this.$eventBus.$on("contentSliderInited", () => {
            // play slide with delay
            this.timeline.play("animateShow", true);
        });

        this.$eventBus.$on("contentSliderChange", (data) => {
            if (this.slideIdx === data.to) this.animateOnShow(data.from, data.to);
            if (this.slideIdx === data.from) { this.animateOnHide(data.from, data.to); }
        });

        this.createTimeline(this.slideType);
    },
    methods: {
        /*
            Optimization:
            1. adding will-change: transform to the most resource intensive ones can provide a significant performance boost — https://greensock.com/will-change
            2. built-in x,y instead of left,top
            3. requestAnimationFrame
        */
        animateOnShow() {
            // seek first show 1 second delay
            this.timeline.play(0.5, false);
            console.log(`${this.slideIdx}. played show`);
        },
        animateOnHide() {
            this.timeline.play("animateHide", false);
            console.log(`${this.slideIdx}. played hide`);
        },
        createTimeline(slideType = "default") {
            const firstSpans = this.$refs.slide.querySelectorAll(".text span[a]");
            const secondSpans = this.$refs.slide.querySelectorAll(".text span[b]");
            let yPercentTop = -100;
            let yPercentBottom = 60;
            let translateX = 100;

            if (slideType === "initial" || slideType === "desc") {
                yPercentTop = slideType === "initial" ? -82 : -51;
                yPercentBottom = slideType === "initial" ? 18 : 49;
                translateX = 0;
            }

            this.timeline
                .addLabel("animateShow")
                .to(
                    this.topText,
                    { yPercent: yPercentTop, alpha: 1, duration: 1 },
                    1,
                )
                .to(
                    this.bottomText,
                    { yPercent: yPercentBottom, alpha: 1, duration: 0.5 },
                    "-=.5",
                )
                .to([this.topTextContainer, this.bottomTextContainer], {
                    overflow: "visible",
                    duration: 0.05,
                })
                .to(firstSpans, { className: "+=crossed" })
                .to(
                    secondSpans,
                    {
                        alpha: 1, duration: 0.5, scale: 1, x: translateX,
                    },
                    "-=.5",
                )
                .to(
                    this.subTitle,
                    {
                        alpha: 1,
                        duration: 0.5,
                        top: () => {
                            const TTcoords = this.topText.getClientRects()[0];
                            return slideType === "initial"
                                ? `${((TTcoords.bottom
                                      + this.subTitle.offsetHeight)
                                      * 100)
                                      / window.innerHeight
                                }%`
                                : `${((TTcoords.top
                                      - 50
                                      - this.subTitle.offsetHeight)
                                      * 100)
                                      / window.innerHeight
                                }%`;
                        },
                    },
                    "-=.5",
                )
                .to(
                    this.readMoreBtn,
                    { alpha: 1, duration: 0.5, bottom: "30%" },
                    "-=.5",
                )
                .addPause("+=0")
                .addLabel("animateHide")
                .to([this.topTextContainer, this.bottomTextContainer], {
                    overflow: "hidden",
                    duration: 0.05,
                })
                .to(
                    [this.topText, this.bottomText],
                    { yPercent: 0, alpha: 0, duration: 1 },
                    "+=.2",
                )
                .to(
                    this.subTitle,
                    { alpha: 0, duration: 0.5, top: "50%" },
                    "-=1.2",
                )
                .to(
                    this.readMoreBtn,
                    { alpha: 0, duration: 0.5, bottom: "50%" },
                    "-=1.2",
                )
                .to(firstSpans, { className: "-=crossed" });
        },
        showCase(caseId) {
            this.$eventBus.$emit("showCase", caseId);
        },
        debounce(func, ms) {
            let timer;
            return function (event) {
                if (timer) clearTimeout(timer);
                timer = setTimeout(func, ms, event);
            };
        },
    },
};
</script>

<style lang="scss">
.slide {
    height: 100vh;
    width: 100vw;
    position: absolute;
    overflow: hidden;
    background: rgba(0, 0, 0, 0.7);

    .top_container {
        height: 50%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        overflow: hidden;
        will-change: transform;
    }
    .bottom_container {
        height: 50%;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 4;
        overflow: hidden;
        will-change: transform;
    }
    .sub_title {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 10%;
        will-change: transition;
        color: #fff;
        font-family: "Roboto Slab", serif;
        line-height: 1.2;
        font-weight: 100;
        font-size: 2rem;
        opacity: 0;

        @include media-breakpoint-down(xs) {
            font-size: 1rem;
        }
    }

    .text {
        position: absolute;
        color: #fff;
        width: 100%;
        font-weight: 700;
        z-index: 2;
        left: 0;
        opacity: 0;
        font-family: Helios, sans-serif;
        font-style: italic;
        line-height: 0.8;
        will-change: transform;
        &.top_text {
            top: 100%;
        }
        &.bottom_text {
            bottom: 100%;
        }

        span[a] {
            opacity: 1;
            position: relative;
            display: inline-block;
            &:before {
                content: "";
                position: absolute;
                transform: translateY(-52%);
                top: 64%;
                left: 0;
                width: 0;
                height: 15px;
                background: $red;
                transition: width 0.3s linear;
            }
            &.crossed:before {
                width: 101%;
            }
        }
        span[b] {
            opacity: 0;
            font-style: normal;
            color: $red;
            transform: scale(1.3);
            display: inline-block;
            font-family: "28days", sans-serif;
            font-size: 6rem;
            font-weight: 100;
        }

        .authors {
            font-family:'Roboto Slab';
            color:#a9a9a9;
            display:block;
            font-size:.9rem;
            margin-top:$space*2;
            .header { display:block; margin-bottom:10px; }
            b { color:#fff }

            @include media-breakpoint-down(xs) {
                font-size:.8rem;
                .header { display:block; }
            }
        }

        @include media-breakpoint-down(lg) {
            font-size: 6.5rem;
            span[b] {
                font-size: 4rem;
            }
        }

        @include media-breakpoint-down(md) {
            font-size: 4rem;
            padding: 0 $space;
            span[b] {
                font-size: 3rem !important;
            }
        }

        @include media-breakpoint-down(xs) {
            font-size: 2.5rem;
            span[b] {
                font-size: 1.7rem !important;
            }
        }
    }

    &.initial {
        .text {
            text-align: center;
            span[a] {
                margin-left: -12px;
            }
            span[b] {
                font-size: 6rem;
                line-height: 0;
            }
        }
        .sub_title {
            left: 50%;
        }
    }

    &.desc {
        .text {
            font-size: 2.5rem;
            line-height: 1.2;
            font-style: normal;

            @include media-breakpoint-down(md) {
                font-size: 2rem;
                span[b] {
                    font-size: 3rem !important;
                }
            }

            @include media-breakpoint-down(xs) {
                font-size: 1.1rem;
                padding: 0 $space;
                span[b] {
                    font-size: 1.15rem !important;
                }
            }
        }
        span[a] {
            &:before {
                transform: translateY(-60%);
                top: 60%;
                height: 6px;
            }
        }
        span[b] {
            font-size: 2.5rem;
            line-height: 0;
        }
    }

    .read_more {
        position: absolute;
        bottom: 50%;
        left: 30%;
        z-index: 4;
        opacity: 0;
    }
}
</style>

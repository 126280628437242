<template>
    <b-navbar type="dark">
        <b-container>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <b-nav-item
                        href="https://skv.ru/"
                        target="_blank"
                        class="logo skv"
                        >S&K Вертикаль
                    </b-nav-item>
                    <b-nav-item
                        href="https://pravo.ru/"
                        target="_blank"
                        class="logo pravo"
                        >ПРАВО.ru
                    </b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav class="m-auto text-center d-none d-md-block" v-if="hasBackButton">
                    <b-nav-item
                        class="back-to-index"
                        @click="
                            $eventBus.$emit('hideCase')
                            $eventBus.$emit('hideQuiz')
                        "
                        >к списку кейсов
                    </b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto">
                    <a href="https://skv.ru/news/analytics/korporativnye-voyny-s-perom-v-rukakh/" target="_blank" class="social social-text align-items-end">PDF</a>
                    <ShareNetwork
                        network="vk"
                        url="https://corporate2021.pravo.ru"
                        title="Корпоративные споры"
                        description="Спецпроект «S&K Вертикаль» и ПРАВО.ru"
                        hashtags="skv,pravo_ru"
                        class="social vk"
                        media="https://corporate2021.pravo.ru/img/og_img.jpg"
                    >
                        VK
                    </ShareNetwork>
                    <ShareNetwork
                        network="twitter"
                        url="https://corporate2021.pravo.ru"
                        title="Корпоративные споры"
                        description="Спецпроект «S&K Вертикаль» и ПРАВО.ru"
                        hashtags="skv,pravo_ru"
                        class="social tw"
                        media="https://corporate2021.pravo.ru/img/og_img.jpg"
                    >
                        Twitter
                    </ShareNetwork>
                </b-navbar-nav>
            </b-collapse>
        </b-container>
    </b-navbar>
</template>

<script>
export default {
    name: "NavBar",
    props: {
        hasBackButton: { default: false },
    },
};
</script>

<style lang="scss">
.navbar {
    position: fixed !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    background: rgba(0, 0, 0, 0.8);

    .back-to-index {
        a {
            font-family: "Roboto Slab", serif;
            color: #fff !important;
            text-transform: uppercase;
            background: $iconShevronLeft no-repeat left center/18px;
            padding-left: 36px !important;
        }
    }

    .logo {
        text-indent: -99999px;
        filter: drop-shadow(0 0 2px #222);
        &.skv {
            width: 190px;
            background: url(../assets/img/skv_logo.png) no-repeat center
                center/100%;
        }
        &.pravo {
            width: 130px;
            background: url(../assets/img/pravo_logo.png) no-repeat center
                center/100%;
            margin-left: 40px;
        }

        @include media-breakpoint-down(sm) {
            &.skv {
                width: 120px;
                background: url(../assets/img/skv_logo.png) no-repeat center
                    center/100%;
            }
            &.pravo {
                width: 80px;
                background: url(../assets/img/pravo_logo.png) no-repeat center
                    center/100%;
                margin-left: 10px;
            }
        }
    }
    .social {
        display: flex;
        margin-left: $space * 3;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center bottom;
        width: 22px;
        height: 22px;
        text-indent: -9999px;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        &:first-child {
            margin-left: 0;
        }
        &.fb {
            background-image: url("~@/./assets/img/socials_fb.svg");
        }
        &.vk {
            background-image: url("~@/./assets/img/socials_vk.svg");
            background-position: 0 4px;
        }
        &.tw {
            background-image: url("~@/./assets/img/socials_twitter.svg");
            background-position: 0 5px;
            background-size: 85%;
        }
        &-text {
            color:#fff; text-indent:0; font-weight:900; line-height:1; font-size:.85rem;
            display:none;
            @include media-breakpoint-up(sm){
                display:flex;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-left: $space;
        }
    }
}
</style>

// eslint ignore-file
export default [
    {
        id: 0,
        title: "Корпоративные <br><span a>споры</span> <span b>войны</span>",
        sub_text: "Отягощённые уголовным<br>преследованием",
        img: "slides_bg/index.jpg",
        case: null,
        article: null,
    },
    {
        id: 1,
        title: `Корпорации… Большие деньги, большие люди и снова деньги. Те, кто не умел вести бизнес, исчезали и вскоре о них никто не вспоминал. Но были те, кто воевал с бумагой и томами дел. Кого-то срезали меткой подписью, кого-то – поддельной. Корпоративные <span a>споры</span> <span b>войны</span> с пером в руках.<br>Мы расскажем все по порядку. Для них это снова была настоящая война.<br><div class="authors"><b class="header">Авторы:</b><b>Алена Бачинская</b>, советник S&K Вертикаль<br><b>Борис Романов</b>, адвокат S&K Вертикаль</div><br><div class="text-right">АБ S&K&nbsp;Вертикаль и «Право.ru»</div>`,
        sub_text: "",
        img: "slides_bg/intro.jpg",
        case: null,
        article: null,
    },
    {
        id: 2,
        title: "Дело<br> <span b>«Норебо»</span>",
        sub_text: "Взятка за минтай и доля акций",
        img: "slides_bg/1.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Дело<br> <span b>«Норебо»</span>",
            text: `
                <p>Когда-то они начинали бизнес втроем. В далеком 1998 году Александр Тугушев, Виталий Орлов и Магнус Рот создали группу «Норебо». Тугушеву досталось управление, Орлов стал исполнительным директором, а Рот занимался деньгами. Компания разрасталась, и Тугушев ушел в замглавы рыбного ведомства, попрощавшись с долей в компании.</p>
                <p>Госслужба закончилась арестом. Тугушев за деньги пообещал квоты на вылов рыбы и сел на шесть лет. Когда он вышел, он захотел свою долю, но Орлов отказал. Разбираться в их конфликте пришлось Высокому суду Англии и Уэльса.</p>
                <p>В деле была безрезультатная попытка оспорить юрисдикцию от Орлова и уголовка от Тугушева. Он пытался доказать, что его долю похитили. Норебо обыскали, а спустя год дело закрыли. Новым ходом стало обвинение в мошенничестве  –  формально дело никак не связано с «Норебо» и Орловым, но юристы Орлова активно о нем напоминают в рамках английского разбирательства.</p>
                <p>Конфликт продолжился в Лондоне, а в январе 2021 года Орлов обратился в Арбитражный суд Москвы, потребовав с Тугушева заплатить 1,2 миллиарда рублей за обыски.</p>                
            `,
            comment: `<p>Итога конфликта пока нет. И каким он будет, во многом зависит от лондонского исхода, решение по которому ожидается в конце 2021 года. Эксперты прогнозируют победу Тугушева, и если так, то он получит контроль над холдингом, а Орлов потеряет бизнес и будет вынужден также выплатить бывшему партнеру ранее полученные дивиденды за пять лет. В зоне риска и сам холдинг, смена собственника может повлечь оспаривание сделок и ограничения на экспорт рыбы в страны, признающие решения английских судов. Уголовно-правовое давление может быть намеком на понуждение к миру.</p>`,
        },
        docs: [
            {
                title: "Решение Лондона",
                url: "https://www.judiciary.uk/wp-content/uploads/2019/03/judgment-tugushev-v-orlov-cl-2018-000498.pdf",
                urlText: "Case #: CL-2018-000498",
            },
            {
                title: "№ А42-5048/2019",
                url: "https://kad.arbitr.ru/Document/Pdf/d6eea22a-efa8-4469-ac92-435201a8806d/51ff14e2-18c7-4533-99dd-695c5811e1b3/A42-5048-2019_20190524_Opredelenie.pdf?isAddStamp=True",
                urlText: "О принятии заявления",
            },
            {
                title: "№ А42-5048/2019",
                url: "https://kad.arbitr.ru/Document/Pdf/d6eea22a-efa8-4469-ac92-435201a8806d/893de795-9e3c-4e95-9010-6ee3f484c65d/A42-5048-2019_20200110_Opredelenie.pdf?isAddStamp=True",
                urlText: "О прекращении производства",
            },
            {
                title: "№ А40-2090/2021",
                url: "https://kad.arbitr.ru/Card/99fb3529-e7cd-4d43-8bdd-03d25902b967",
                urlText: "Московское дело",
            },
        ],
    },
    {
        id: 3,
        title: "Роковой<br> <span b>опцион</span>",
        sub_text: "«Баринг Восток» и Калви",
        img: "slides_bg/2.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Роковой<br> <span b>крупном</span>",
            text: `
                <p>Сначала он купил акции банка через свой фонд. Так Майкл Калви и его Baring Vostok получили контроль над банком «Восточный». Меньший пакет был у инвестора Артема Аветисяна. Отчасти подконтрольный ему банк «Юниаструм» слился с «Восточным».</p>
                <p>Перед объединением двух банков стороны заключили опцион: Аветисян сможет выкупить 9,99% акций «Восточного», а если что, то – встретимся в суде. В лондонском третейском суде (LCIA). Пакет акций поменял бы контролирующего акционера, но мирно это не случилось –  Калви заблокировал исполнение опциона и потребовал признать его недействительным в LCIA, объяснив это выводом активов из «Юниаструма» перед объединением. Вскоре Калви и других менеджеров «Восточного» задержали по подозрению в хищении 2,5 млрд. рублей. Якобы Калви по завышенной цене зачел ранний долг банку чужими акциями. Обвинял его Аветисян.</p>
                <p>Последовала череда исков и взаимных обвинений. Параллельно с лондонским процессом дело шло в Арбитражном суде Амурской области, где Аветисян добился исполнения опциона.</p>
                <p>Вскоре обвинение Калви смягчили, а затем стороны заключили мировую и отказались от претензий друг к другу. Сразу после этой новости Калви отпустили из-под домашнего ареста, запретив определенные действия. Процесс о растрате еще идет.</p>
            `,
            comment: `
                <p>Да, это назвали растратой, но даже на стадии следствия оценка акций кардинально отличалась, поэтому позиция обвинения выглядит не вполне обоснованной.</p>
                <p>Арбитражная оговорка не гарантирует, что суд не останется в России. Арбитражному суду Амурской области не помешал Высокий суд Лондона, и он рассмотрел дело, несмотря ни на наличие параллельного спора в LCIA по признанию опциона недействительным, ни на прямой судебный запрет LCIA на рассмотрение дела.</p>
                <p>С учетом возбужденного уголовного дела, промежуточного решения LCIA и решения Арбитражного суда Амурской области, заключение мирового соглашения по итогам конфликта ожидаемо и, очевидно, соответствует интересам обеих сторон.</p>
            `,
        },
        docs: [
            {
                title: "",
                url: "https://kad.arbitr.ru/Card/3016ea4e-f7ad-459d-a6d3-0b1b248d009c",
                urlText: "Дело № А04-1784/2019 ",
            },
        ],
    },
    {
        id: 4,
        title: "Похищен&shy;ная<br> <span b>выручка</span>",
        sub_text: "«Тольяттиазот»",
        img: "slides_bg/3.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Похищен&shy;ная<br> <span b>выручка</span>",
            text: `
                <p>Долгие годы продолжается конфликт между акционерами-основателями «Тольяттиазота» – отцом и сыном Махлаями и миноритарием «Уралхима» Дмитрием Мазепиным.  Иногда даже с 10% акций можно устроить настоящее побоище в судах.</p>
                <p>В 2012 году по заявлению «Уралхима» следствие заподозрило  отца и сына в мошенничестве: якобы те продавали своим приятелям аммиак и карбид по заниженной цене. Похищенное оценили в 84 млрд рублей, и в июле 2019 года <a href="https://pravo.ru/arbitr_practice/courts/2497/">Комсомольский районный суд г. Тольятти</a> заочно приговорил каждого из них по ч. 4 ст. 159 УК (мошенничество в особо крупном размере) к девяти годам колонии. Махлаи в розыске, а приговор вступил в законную силу. И не только он. Суд удовлетворил и гражданский иск «Уралхима» на сумму более 87 млрд рублей, из которых 77 млрд руб. в качестве возмещения ущерба получит ПАО «Тольяттиазот», а 10 млрд руб. – «Уралхим».</p>
                <p>Конфликт не остался в России. Мажоритарии в Ирландском суде пытаются доказать, что Мазепин с компанией хотят захватить ТоАз. В ответ летят обвинения в затягивании исполнения. Слушания в Ирландском суде несколько раз переносились, в последний раз – до февраля 2021 года.</p>
                <p>Тем временем в январе 2020 года в отношении находящегося в розыске Сергея Махлая возбудили новое уголовное по ч. 3 ст. 30 и ч. 5 ст. 291 УК – покушение на дачу взятки в особо крупном размере судьям Верховного суда за отмену решения о взыскании с ПАО «Тольяттиазот» налоговых недоимок. Оно сейчас рассматривается в Центральном райсуде Тольятти.</p>
            `,
            comment: `
                <p>Интересно, что деньги взыскали в гражданских исках при уголовных делах. Почти то же самое сейчас рассматривают в Ирландии, и если вдруг решение будет иным, то как разрешится эта коллизия?</p>
            `,
        },
        docs: [
            {
                title: "",
                url: "https://komsomolsky--sam.sudrf.ru/modules.php?name=sud_delo&name_op=case&_id=121506164&_uid=0b73745a-7db6-4491-92b7-0db075dc38ff&_deloId=1540006&_caseType=&_new=0&srv_num=1",
                urlText: "Дело № 1-1/2019 (1-53/2018)",
            },
        ],
    },
    {
        id: 5,
        title: "Золотые<br> <span b>рудники</span>",
        sub_text: "Дело Литуева",
        img: "slides_bg/4.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Золотые<br> <span b>рудники</span>",
            text: `
                <p>Порой уголовные дела «возникают», когда у компании появляются новые инвесторы и акционеры, которые начинают конфликтовать со старыми. Такой сценарий ждал золоторудные активы в Забайкалье. В 2014 году Виктор Литуев, которому принадлежал контрольный пакет акций ПАО «Ксеньевский прииск», искал инвесторов в свой золотодобывающий бизнес. И нашел. Сергей Янчуков выкупил долги прииска в обмен на 50% акций предприятия. Закрутились новые договоренности и учрежденные компании. Кто-то что-то нарушил, понял не так, не выполнил…</p>
                <p>Литуев потребовал через суд акции назад и сверху неустойку в размере 495,5 млн руб. А Литуева-младшего внезапно обвинили по ч. 4 ст. 160 УК («Присвоение или растрата») и двум эпизодам ч. 3 ст. 260 УК («Незаконная вырубка леса в особо крупном размере группой лиц по предварительному сговору»). Сначала его содержали под стражей, потом отпустили под залог. Защитники Литуева назвали то уголовное дело элементом силового давления в корпоративном конфликте. В итоге все споры между партнерами удалось разрешить мирным путем прошлой осенью.</p>
            `,
            comment: `<p>Ох уж эти «корпоративные покровы». В рамках арбитражных дел суды отметили, что множество соглашений на самом деле связано, и по сути был лишь один корпоративный договор по воле конечных бенефициаров – Литуева и Янчукова. Все игры со сторонними участниками суд отмел, потому как использовались они всего лишь для структурирования сделки.</p>`,
        },
        docs: [
            {
                title: "",
                url: "https://kad.arbitr.ru/Card/2bf822f6-3a40-4c84-8dc7-555643d25659",
                urlText: "Дело № А40-207551/2018",
            },
        ],
    },
    {
        id: 6,
        title: "Полно&shy;масштаб&shy;ный<br> <span b>развал</span>",
        sub_text: "Кейс «Юлмарта»",
        img: "slides_bg/5.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Полно&shy;масштаб&shy;ный<br> <span b>развал</span>",
            text: `
                <p>Четыре предпринимателя через мальтийскую Ulmart Holding владели компанией «Юлмарт» плюс-минус в равных долях: Дмитрий Костыгин (26% акций ретейлера), его партнер <a href="http://whoiswho.dp.ru/cart/person/1918696/">Август Мейер</a> (28%), Михаил Васинкевич (26%) и Алексей Никитин (20%). А потом начался конфликт: в 2016 году партнеры не смогли договориться о развитии бизнеса.</p>
                <p>Никитин и Васинкевич собрались выйти из бизнеса и <a href="https://www.dp.ru/a/2016/09/21/Sovladelec_JUlmarta_Kost">потребовали</a>  через Международный арбитраж Лондона от Костыгина и Мейера выкупить их доли. Сумма показалась им завышенной, и они отказались. Сделка так и не состоялась. А вскоре добавились новые игроки – консультанты по слияниям и поглощениям инвесткомпании «А1», которых пригласил Васинкевич. Совет директоров, где были представители каждой из противоборствующих сторон, был блокирован.</p>
                <p>Вскоре подтянулись кредиторы. Еле договорившись с «Уралсибом», компания встретила Сбербанк с требованием долга в 1 млрд. рублей. Началось уголовное дело: якобы Костыгин получал кредит для «Юлмарта» по подложным документам – скрыл кучу долгов. Это дело еще идет.</p>
                <p>Кроме того, по заявлению Костыгина суд занялся банкротством Васинкевича. Сейчас <a href="https://kad.arbitr.ru/Card/b3520655-67b3-4ef6-9fd5-696f8ec6df4d">идет продажа</a> имущества с торгов.  Сумма требований там составила 405,3 млн руб. А в 2020-м банкротом по решению суда стал и сам «Юлмарт». Одним из самых крупных его кредиторов значится Сбербанк.</p>
            `,
            comment: `<p>Итоги этого корпоративного конфликта печальны для всех. Личные банкротства участников, Костыгин под следствием, конкурсное производство в «Юлмарте». Можно ли было всего этого избежать, и когда наступила точка невозврата?</p><p>Иногда лучше мирно «развестись», используя инструменты выкупа долей у миноритариев по устраивающей всех цене, привлечение внешних инвесторов, заключение соглашений, чем полномасштабная война.</p>`,
        },
        docs: [
            {
                title: "",
                url: "https://kad.arbitr.ru/Card/5198f2fa-3126-48ff-878f-8ee79bc953ae",
                urlText: "Дело №А56-78582/2016",
            },
        ],
    },
    {
        id: 7,
        title: "Стройка или<br> <span b>мошенни&shy;чество</span>",
        sub_text: "Дело Худояна",
        img: "slides_bg/6.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Стройка или<br> <span b>мошенни&shy;чество</span>",
            text: `
                <p>Пакет уголовных дел вместо стройки на Ленинградском проспекте Москвы. Клубок разворачивает следствие, но началось все с дела против Альберта Худояна, главы Optima Development. Бывшие партнеры утверждают, что Худоян похитил их активы, а тот – что затеяно рейдерство.</p>
                <p>Заявление подал Сергей Говорухин, который сам уже был в федеральном розыске по делу бывшего депутата Госдумы Ильдара Самиева из-за махинаций с загородной недвижимостью. У самого Самиева были (или нет) акции «Оптимы».  А партнер Самиева Сергей Говядин также признан потерпевшим по делу о мошенничестве, возбужденному в отношении Худояна.</p>
                <p>Предпринимателя обвиняют в хищении нескольких земельных участков, сумму ущерба следствие оценило в 2 млрд руб. Якобы он передал права аренды в Москве от «одной российской организации другой».</p>
            `,
            comment: `<p>Интересно, что основная часть конфликта на территории России разворачивается в уголовном поле, а не в арбитражных судах, поскольку иски с участием иностранных компаний участников проекта российские суды рассматривать не стали. Вероятно, гражданско-правовые вопросы конфликта относительно российского актива разрешаются в судебных делах на территории оффшорных юрисдикций. Не исключено, что полномасштабный выход в арбитражные и гражданские дела произойдет уже после установления необходимых обстоятельств в рамках следствия. Наблюдаем.</p>`,
        },
        docs: [
            {
                title: "",
                url: "https://kad.arbitr.ru/Card/551ec7eb-a4c0-4596-8894-06be76f1cf17",
                urlText: "Дело № А40-207211/2020",
            },
            {
                title: "",
                url: "https://mos-gorsud.ru/rs/koptevskij/services/cases/criminal/details/1dec82d0-549d-11eb-b1cf-67a67c86f08b?respondent=%D0%A5%D1%83%D0%B4%D0%BE%D1%8F%D0%BD",
                urlText: "Дело № 01-0051/2021",
            },
        ],
    },
    {
        id: 8,
        title: "Смена<br> <span b>руководства</span>",
        sub_text: "«Росгосстрах» и Хачатуров",
        img: "slides_bg/7.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Смена<br> <span b>руководства</span>",
            text: `
                <p>Покупка банком «Открытие» крупнейшего страховщика – компании «Росгосстрах» – обернулась для их владельцев Вадима Беляева и Данила Хачатурова потерей бизнеса. Убытки по «ОСАГО» какое-то время гасил банк, а потом попал под санацию Центробанка, начались проверки, а за ними и уголовные дела. ЦБ считает, что бизнесмены выводили активы, через передачу акций в подконтрольные компании прямо перед сделкой.</p>
                <p>Это дело после ряда обжалований закончилось сроками: Хачатуров получил четыре года общего режима, Надежда Клепальская, участвовавшая в играх с акциями, – три года условно.</p>
                <p>Параллельно с уголовным делом завертелось разбирательство и в арбитражной плоскости. Спорили о разрыве отношений с «РГС жизнью», и тут дело дошло даже до Президиума Верховного суда, который вернул его в самое начало – изучать сроки давности. Спорят по второму кругу о выводе из «Росгосстраха» компании «Медис», владеющей сетью поликлиник. Под прицелом нового руководства «Росгосстраха» еще несколько сделок. Пока до финала далеко.</p>
            `,
            comment: `<p>Смена руководства в «Росгосстрахе» ожидаемо привела к исследованию причин кризиса в компании и проверке сделок прошлого хозяина. В крупнейшем споре, который дошел до Президиума ВС, суд встал на сторону нового руководства компании, напомнив, что если был сговор, то срок давности идет с момента, когда о нем узнали.</p><p>Однако в деле о передаче компании «Медис» суды по итогам двух кругов рассмотрения дела пришли к выводу, что оспариваемые сделки не взаимосвязаны и, по сути, были обычными для компании.</p>`,
        },
        docs: [
            {
                title: "",
                url: "https://kad.arbitr.ru/Card/c45828b8-4aa2-4dc7-866d-ed17249a5ad7",
                urlText: "Дело № А40-127011/2018",
            },
        ],
    },
    {
        id: 9,
        title: "Бой<br> <span b>минори&shy;тариев</span>",
        sub_text: "«Кировский завод»",
        img: "slides_bg/8.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Бой<br> <span b>минори&shy;тариев</span>",
            text: `
                <p>Трагическая гибель отца, главы «Кировского завода», привела его сына Георгия Семененко в 2005 году в управленцы. Но миноритарные акционеры (в сумме контролируют более 20% акций завода) пытались доказать в судах, что младший Семененко с родственниками попросту перехватили завод. В вину поставили странную продажу пакета акций. Целых 35 % якобы ушли по очень низкой цене в семью Семененко с балансом на Кипре. Дело об убытках в 600 млн евро, якобы причиненных «Кировскому заводу» и его акционерам, тянулось долгие годы.</p>
                <p>А в прошлом году миноритарии отозвали свои иски, в том числе и в российских судах. Многолетний конфликт между акционерами ПАО «Кировский завод» близится к завершению.</p>
            `,
            comment: `<p>Легендарный конфликт, который начался еще в 2005 году после смерти владельца «Кировского завода», в прошлом году закончился миром. Ключевой процесс все еще шел на Кипре, но, похоже, спор уже всем надоел, стороны конфликта нашли взаимовыгодное решение, не став дожидаться окончания дела в суде. И это похвально.</p>`,
        },
        docs: [
            {
                title: "",
                url: "https://kad.arbitr.ru/Card/b456933b-cb41-4a01-a55f-e89c16460181",
                urlText: "Дело № А56-1486/2010",
            },
        ],
    },
    {
        id: 10,
        title: "Поговорим про<br> <span b>опцион</span>",
        sub_text: "«Деловые линии»",
        img: "slides_bg/9.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Поговорим про<br> <span b>опцион</span>",
            text: `
                <p>Александр Богатиков и Михаил Хабаров выступали как совладельцы «Деловых линий» с 2015 года. Они договорились об опционе: фирма Хабарова Caledor Consulting могла выкупить 30% доли в «Деловых линиях» по фиксированной цене. Но спустя два года партнеры поругались и акции ушли разным физлицам.</p>
                <p>Caledor Consulting отправилась судиться в Лондон (LCIA) за $58 млн за невыполнение условий опциона. Спор выиграли, но АС Санкт-Петербурга и Ленобласти отказался признавать это решение: посчитали в Лондоне неправильно. В декабре прошлого года Высокий суд Лондона предписал пересмотреть предыдущее решение LCIA. Лондонский арбитраж это сделал в 2021 году, вновь подтвердив право структуры Хабарова на возмещение за нарушение условий опциона, на сей раз в размере $49 млн. В конце марта текущего года Caledor обратились за признанием этого решения в АС СПб и Ленобласти (дело № А56-25416/2021).  Это заявление суд пока оставил без движения. Тем временем в России разворачивается уже уголовное дело.</p>
                <p>Богатиков утверждает, что Хабаров взял у него 842 млн руб. за остановку налоговых проверок в «Деловых линиях». Хотя по факту у Хабарова не было никаких возможностей, чтобы как-то повлиять на действия налоговиков. Расследование все еще идет.</p>
                <p>Одновременно конфликт развернулся и в другой плоскости: о своих правах заявил бывший партнер Богатырев, который потребовал с Богатикова 735 млн руб. – столько он потерял на сделке по продаже доли в 2015 году. Лондонский арбитраж установил, что «Деловые линии» уклонялись от уплаты налогов, а значит, при продаже доля Богатырева была оценена с занижением.</p>
            `,
            comment: `<p>Интересная коллизия будет, если выводы российского следствия вступят в противоречие с решением международных судов. А пока имеем что имеем.</p><p>В Лондоне решили, что деньги нужно взыскать, а вот признают ли решение в РФ, пока вопрос открытый. Следствие все еще изучает, мог ли Богатиков вообще заключить соглашение об опционе.</p>`,
        },
        docs: [
            {
                title: "Пересмотр решения Высоким судом Англии",
                url: "https://www.casemine.com/judgement/uk/5fcdc09e2c94e0485ac93654",
                urlText: "Case #: CL-2020-000148",
            },
        ],
    },
    {
        id: 11,
        title: "Внезапные<br> <span b>наследники</span>",
        sub_text: "Конфликт в «Сибантраците»",
        img: "slides_bg/10.jpg",
        case: { number: "123123123", url: "#" },
        article: {
            title: "Внезапные<br> <span b>наследники</span>",
            text: `
                <p>В мае прошлого года умер миллиардер, основатель ГК «Сибантрацит» Дмитрий Босов, и вскоре на империю нашлись наследники. Родители, дети, супруги. А еще в споры включились бывшие партнеры.</p>
                <p>Уволенный незадолго до трагедии  член совета директоров «Сибантрацит», совладелец компании «Востокуголь» Александр Исаев занялся оспариванием (и, надо сказать, успешным) потери 50% акций «Востокугля».  Параллельно наследникам умершего пришлось судиться с «Ростехом» за порт Вера в Приморье и Огоджинский угольный проект в Амурской области. Мать Босова и его дети спорили с последней женой о переходе доли в холдинговой компании «Аллтек» (контролирует ГК), и ее включении в наследственную массу. Все? Не уверены.</p>
                <p>Сейчас стороны по многочисленным процессам веерно заключают мировые соглашения.</p>
            `,
            comment: `<p>Корпоративный конфликт, за счет его мирного урегулирования, длился относительно недолго, но включал в себя войну в СМИ и несколько десятков судебных процессов.</p><p>Яркий пример, как может разгореться война в случае смены контроля в компании по причине смерти одного из главных владельцев. В таких историях всегда есть риск неожиданного поведения наследников, в том числе споров между ними и отказа с их стороны в соблюдении статуса кво. Перспективы неизвестны, и их сложно предугадать, мировая вызывает уважение.</p><p>Стороны достигли компромисса и избежали многолетних судебных разбирательств с непрогнозируемым исходом.</p>`,
        },
        docs: [
            {
                title: "",
                url: "https://kad.arbitr.ru/Card/9801ec0a-cd34-4da2-9aaf-ee9ec277ca43",
                urlText: "Дело №А41-28135/20",
            },
        ],
    },
];

export const QUIZ = {};

import "reset-css";

import Vue from "vue";
// prettier-ignore
import {
    BootstrapVue, NavbarPlugin,
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import VueSocialSharing from "vue-social-sharing";

import App from "./App.vue";
import "./assets/scss/style.scss";
import "hamburgers/dist/hamburgers.css";

Vue.config.productionTip = false;
Vue.prototype.$eventBus = new Vue();

Vue.use(BootstrapVue);
Vue.use(NavbarPlugin);

Vue.use(VueSocialSharing);

new Vue({
    render(h) {
        return h(App);
    },
}).$mount("#app");
